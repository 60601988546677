<template>
  <div class="android-app-modal">
    <div class="android-app-modal__icon">
      <android-app-icon />
    </div>
    <div class="android-app-modal__content">
      <h4 class="android-app-modal__title">
        Покупки удобнее <br />в приложении
      </h4>
      <ul class="android-app-modal__list">
        <li
          v-for="(item, index) in listItems"
          :key="index"
          class="android-app-modal__list-item"
        >
          <check-fat-icon
            width="12"
            height="12"
            class="android-app-modal__list-icon"
          />
          {{ item }}
        </li>
      </ul>
      <a
        href="https://play.google.com/store/apps/details?id=ru.book24site.twa&utm_source=book24&utm_medium=referral&utm_campaign=app_popup"
        class="b24-btn android-app-modal__button"
        target="_blank"
        @click="hideModalAndroidApp"
      >
        Установить
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useCommonModal from '@/compositions/useCommonModal';

// icons
import AndroidAppIcon from '@/assets/sprite/svg/android-app-icon.svg';
import CheckFatIcon from '@/assets/sprite/svg/16/Check_fat_16.svg';

export default defineComponent({
  name: 'AndroidAppModal',
  components: {
    AndroidAppIcon,
    CheckFatIcon,
  },
  setup() {
    const { hideModalAndroidApp } = useCommonModal();

    const listItems = [
      'Ищите авторов и книги',
      'Делайте заказы за пару кликов',
      'Копите и тратьте бонусы',
    ];

    return {
      listItems,
      hideModalAndroidApp,
    };
  },
});
</script>

<style lang="less">
.android-app-modal {
  position: relative;
  padding: 2px 22px @size-x3;

  &__icon {
    position: absolute;
    top: -38%;
    left: 22px;
    z-index: 5;
  }

  &__title {
    .h-reset;
    .h2-mobile(28px);
  }

  &__list {
    .list-reset;
    .p;

    margin-top: @size-x4;
    margin-bottom: @size-x6;
  }

  &__list-item {
    position: relative;
    margin-bottom: @size-x2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list-icon {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translate(0, -50%);
    fill: @pink;
  }

  &__button {
    width: 132px;
  }
}
</style>
